<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="editApi.isError"
      :api="editApi"
      :callbackReset="() => (editApi.isError = false)"
    ></AError>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col cols="12" sm="6">
          <h2>Edit Teacher</h2>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex justify-center justify-sm-end">
            <div>
              <v-btn 
                v-if="data.User && data.Institution && (data.User.accountId == data.Institution.accountId)"
                rounded
                outlined
                disabled
              >
                CANNOT DELETE ADMIN
              </v-btn>
              <v-btn v-else rounded color="red" outlined :to="{name:'PageEducatorInstitutionTeacherDelete',params:{id:this.$route.params.id,parentId:this.$route.params.parentId}}"> Delete </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <TextField
                :value="name"
                label="Full name"
                :rules="nameRules"
                type="text"
                @input="name = $event"
              ></TextField>
            </div>
            <div class="text-center my-0">
              <SelectField
                :value="position"
                :field="selectField"
                @input="position = $event"
                label="Position"
              ></SelectField>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                :loading="editApi.isLoading"
                rounded
                color="primary"
                dark
                @click="submit()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import TextField from "../../components/Form/TextField.vue";
import SelectField from "@/components/Form/SelectField.vue";
export default {
  components: {
    TextField,
    SelectField,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    valid: true,
    name: null,
    position: null,
    nameRules: [],
    data:{},
    id: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
      //BOC:[api]
      classroomApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    positionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    selectField: {
      defaultValue: "",
      name: "Position",
      options: [],
      optionText: "name",
      optionValue: "id",
    },
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Teachers",
        to: {
          name: "PageEducatorInstitutionTeacher",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Teacher",
        to: {
          name: "PageEducatorInstitutionTeacherRead",
          params: { 
            id: this.$route.params.id,
            parentId: this.$route.params.parentId,
          },
        },
        exact: true,
      },
      {
        text: "Edit",
        to: {
          name: "PageEducatorInstitutionTeacherEdit",
          params: { 
            id: this.$route.params.id,
            parentId: this.$route.params.parentId,
          },
        },
        exact: true,
      },
    ]
    //EOC
    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.parentId+
      "/teacher/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.name = resp.User.name;
      this.position = resp.positionId
      this.data = resp
      // this.updateBreadcrumb(resp)
    };

    this.positionApi.method = "GET";
    this.positionApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/data/position";

    this.positionApi.callbackReset = () => {
      this.positionApi.isLoading = true;
      this.positionApi.isError = false;
    };

    this.positionApi.callbackError = (e) => {
      this.positionApi.isLoading = false;
      this.positionApi.isError = true;
      this.positionApi.error = e;
    };

    this.positionApi.callbackSuccess = (resp) => {
      this.positionApi.isLoading = false;
      this.selectField.options = resp
      // this.updateBreadcrumb(resp)
    };
    //EOC
    
    this.editApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.parentId +
      "/teacher/edit";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = () => {
      this.editApi.isLoading = false;
      this.$router.push({ name: 'PageEducatorInstitutionTeacher', params: { id:  this.$route.params.parentId } });
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.$api.fetch(this.api);
    this.$api.fetch(this.positionApi);
  },
  watch: {
    name: function () {
      this.nameRules = [];
    },
  },
  methods: {
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs.push({
    //     text: "Dashboard",
    //     to: { name: "PageEducatorDashboard" },
    //     exact: true,
    //   });
    //   this.breadcrumbs.push({
    //     text: resp.Institution.name,
    //     to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.parentId} },
    //     exact: true,
    //   });
    
    //   this.breadcrumbs.push({
    //   text: "Teachers",
    //   to: { name: "PageEducatorInstitutionTeacher" ,params:{id:this.$route.params.parentId} },
    //   exact: true,
    // });
    // this.breadcrumbs.push({
    //   text: resp.User.name,
    //   to: { name: "PageEducatorInstitutionTeacherRead" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId,} },
    //   exact: true,
    // });

    // this.breadcrumbs.push({
    //   text: "Edit",
    //   to: { name: "PageEducatorInstitutionTeacherEdit" },
    //   exact: true,
    // });
    // },
   
    submit() {
      this.nameRules = [(v) => !!v || "Name is required"];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formName.validate()) {
          self.editApi.params = {
            id: self.id,
            name: self.name,
            positionId:self.position
          };
          self.$api.fetch(self.editApi);
        } else {
          self.editApi.callbackError("Please check the form.");
        }
      });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>